@charset "UTF-8";

.box-noticia{
	min-height: 150px;
}

.noticia-item{
	border-bottom: 1px solid #eee;
	margin: 0px 0px 15px;
	padding-bottom: 10px;
	&.noticia-aberta{
		border-bottom: none;
		padding-bottom: 0px;
		margin: 0px;
	}
	a{
		display: block;
		text-decoration: none;
		h4{
			@include oxygen-bold();
			font-size: 18px;
			color: $blue;
			text-align: left;
			padding: 5px 2px;
			margin: 0px;
		}
	}
	a:hover,
	a:focus{
		color: $blue-hover;
	}
	h3{
		@include oxygen-bold();
		font-size: 24px;
		color: $blue;
		text-align: left;
		padding: 5px 10px 5px 2px;
		margin: 0px;
	}
	small{
		display: block;
		font-size: 13px;
		color: $less-gray;
		text-align: left;
		padding: 5px 2px;
		margin: 0px 0px 20px;
	}
	p{
		font-size: 16px;
		color: $dark;
		text-align: left;
		padding: 5px 2px;
		margin: 0px;
		text-indent: 30px;
	}
	&.noticia-relacionados{
		h4{
			font-size: 14px;
		}
	}
	.noticia-imagem{
		width: 300px;
		height: 300px;
		float: left;
		margin: 0px 20px 20px 0px;
		img{
			width: 100%;
			height: auto;
		}
	}
	.noticia-imagem-sm{
		width: 100px;
		height: 100px;
		float: left;
		margin: 0px 10px 10px 0px;
		img{
			width: 100%;
			height: auto;
		}
	}
}

.group-btn-like{
	text-align: center;
	margin: 20px 0px;
}

.btn-like-up{
	display: inline-block;
	width: 65px;
	height: 65px;
	background: url('/assets/img/components/like-up.png') no-repeat no-repeat center center;
	&.active{
		background: url('/assets/img/components/like-up-active.png') no-repeat no-repeat center center;
	}
}

.btn-like-down{
	display: inline-block;
	width: 65px;
	height: 65px;
	background: url('/assets/img/components/like-down.png') no-repeat no-repeat center center;
	margin-bottom: -10px;
	&.active{
		background: url('/assets/img/components/like-down-active.png') no-repeat no-repeat center center;
	}
}

.like-label{
	display: inline-block;
  padding: 15px;
  margin: 15px 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: #ccc;
  border: 2px solid #f3f3f3;
  border-radius: 15px;
  position: relative;
  top: -20px;
}

.result-up{
	display: inline-block;
	font-size: 22px;
  font-weight: 800;
  line-height: 22px;
	color: #39CB4B;
	margin: 10px 15px;
	position: relative;
  top: -20px;
}

.result-down{
	display: inline-block;
	font-size: 22px;
  font-weight: 800;
  line-height: 22px;
	color: #CC334A;
	margin: 10px 15px;
	position: relative;
  top: -20px;
}

