.process-title {
  margin-bottom: 20px;
}

.process-card {

  .content-wrapper {
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.37);
    transition: box-shadow 0.28s cubic-bezier(0.4,0,0.2,1);

    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.3);
    }

    &:hover .card-text {
      text-decoration: underline;
    }

    .image-wrapper {
      height: 200px;
      padding: 15px;
    }
  }

  &:nth-child(3n + 1) .content-wrapper .image-wrapper {
    background: #ff4081;
  }

  &:nth-child(3n + 2) .content-wrapper .image-wrapper {
    background: #4accfb;
  }

  &:nth-child(3n + 3) .content-wrapper .image-wrapper {
    background: #1de9b6;
  }

  .card-icon {
    font-size: 11rem;
    width: 100%;
    text-align: center;
    color: #fff;
  }

  .card-text {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    color: #000;
    padding: 15px;
    min-height: 70px;
  }
}