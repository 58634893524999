/* Cor dos textos - cinza claro*/
$gray-text: #bab9b9;

/* Cinza escuro para titulos*/
$less-gray: #bbbbbb;
$gray: #959393;
$gray-dark: #7e8081;
$dark: #4C5252;

/* Cor de fundo para links (Hover, Focus) */
$bg-half-gray: #d6d6d6;
$bg-half-white: #e2e2e2;
$bg-almoste-white: #f7f7f7;

/* Padrão de azul*/
$blue: #155679;
$blue-hover: #1e72a0;

/* Cores do menu */
$menu-hover: #000;

/* Cor do texto do menu*/
$white: white;
$less-white: #eeeeee;
$gray: #A9A9A9;

/* A cor de fundo do dropdown de veja + e serviços*/
$bg-blue-dropdown: #1a7bbb;
$bg-red-dropdown: #d54e3d;
$bnt-servicos-red-hover: #741003;

/* Faixa do menu */
@mixin bg-menu-blue(){
  background: #2e2f98;
}

/* Botão do veja mais no menu*/
@mixin bnt-login-blue(){
  background: #155679;
}

@mixin sub-titulo-gray() {
  color: #ccc;
}