@charset "UTF-8";

.box-biblioteca{
	min-height: 50px;
}
.box-biblioteca-titulo{
	a{
		text-decoration: none;
		h1{
			@include titulo-blue();
			text-align: center;
			&:hover{
				color:$blue-hover;
			}
		}		
	}
}

.biblioteca-itens{
	border: 1px solid $bg-half-white;
	margin: 0px 0px 10px;
	a{
		display: block;
		background: $bg-almoste-white;
		text-decoration: none;
		h4{
			@include oxygen-bold();
			font-size: 22px;
			color: $gray-dark;
			text-align: left;
			padding: 10px 15px;
			margin: 0px;
		}
	}
	a:hover,
	a:focus{
		background: $bg-half-white;
	}
}

.biblioteca-result-iten{
	border-bottom: 1px solid #eee;
	margin: 0px 0px 10px;
	a{
		display: block;
		text-decoration: none;
		h4{
			@include oxygen-bold();
			font-size: 18px;
			color: $blue;
			text-align: left;
			padding: 5px 2px;
			margin: 0px;
		}
	}
	a:hover,
	a:focus{
		color: $blue-hover;
	}
	p{
		font-size: 14px;
		color: $gray-dark;
		padding: 5px 2px;
		margin: 0px;
	}
	em{
		display: block;
		font-size: 12px;
		color: $less-gray;
		text-align: left;
		padding: 10px 2px;
		margin: 0px;
	}
}
