.select2-container:before,
.select2-drop:before,
.select2-choices:before,
.select2-search-field:before,
.select2-search-field input:before,
.select2-search:before,
.select2-search input:before,
.select2-container:after,
.select2-drop:after,
.select2-choices:after,
.select2-search-field:after,
.select2-search-field input:after,
.select2-search:after,
.select2-search input:after {
  content: " ";
  display: table;
}
.select2-container:after,
.select2-drop:after,
.select2-choices:after,
.select2-search-field:after,
.select2-search-field input:after,
.select2-search:after,
.select2-search input:after {
  clear: both;
}
.select2-container,
.select2-container.form-control {
  border: none;
  margin: 0;
  padding: 0;
}
.select2-container {
  display: inline-block;
  height: auto !important;
  position: relative;
  /* inline-block for ie7 */
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  width: 100%;
}
.select2-container .select2-choice {
  background-clip: padding-box;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #d6d6d6;
  border-top-color: #c2c2c2;
  border-radius: 2px;
  color: #555555;
  display: block;
  font-size: 13px;
  line-height: 1.42857143;
  height: 32px;
  overflow: hidden;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-container .select2-choice > .select2-chosen {
  display: block;
  margin-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-choice .select2-search-choice-close {
  border: 0;
  color: #888;
  cursor: pointer;
  display: none;
  font-size: 11px;
  line-height: 30px;
  height: 30px;
  right: 18px;
  width: 24px;
}
.select2-container .select2-choice .select2-search-choice-close:hover {
  color: #aaa;
  cursor: pointer;
}
.select2-container .select2-choice .select2-arrow {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 26px;
}
.select2-container .select2-choice .select2-arrow b {
  color: #555555;
  display: block;
  font-family: FontAwesome;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  height: 100%;
  margin-top: 1px;
  padding: 6px 0;
  text-align: center;
  width: 100%;
}
.select2-container .select2-choice .select2-arrow b:before {
  content: "\f0d7";
}
.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #aaa;
  border-radius: 0 0 2px 2px;
}
.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}
.select2-container.select2-allowclear .select2-choice .select2-search-choice-close {
  display: block;
}
.select2-drop {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-top: 0;
  border-radius: 0 0 2px 2px;
  color: #555555;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9999;
}
.select2-drop.select2-drop-above {
  border-bottom: 0;
  border-top: 1px solid #aaa;
  border-radius: 2px 2px 0 0;
  margin-bottom: -1px;
  margin-top: 1px;
}
.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}
.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}
.select2-drop-active {
  border-top: none;
}
.select2-search {
  display: block;
  margin: 0;
  min-height: 17px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  z-index: 10000;
}
.select2-search:after {
  color: #888;
  content: "\f002";
  display: block;
  font-family: FontAwesome;
  font-size: 14px;
  text-align: center;
  position: absolute;
  right: 9px;
  top: 10px;
  width: 30px;
}
.select2-search input {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #d6d6d6;
  border-top-color: #c2c2c2;
  border-radius: 2px;
  color: #555555;
  font-size: 12px;
  line-height: 15px;
  height: auto !important;
  margin-top: 8px;
  min-height: 17px;
  outline: none !important;
  padding: 4px 9px;
  padding-right: 30px;
  position: relative;
  width: 100%;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  outline: none;
}
.select2-dropdown-open .select2-choice {
  border-bottom-color: #aaa;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-dropdown-open .select2-choice .select2-arrow b:before {
  content: "\f0d8";
}
.select2-results {
  margin: 8px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}
.select2-results ul.select2-result-sub > li .select2-result-label {
  padding-left: 20px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 40px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 60px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 80px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 100px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 110px;
}
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 120px;
}
.select2-results li {
  background-image: none;
  display: block;
  list-style: none;
}
.select2-results li em {
  background: #feffde;
  font-style: normal;
}
.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: 600;
}
.select2-results .select2-result-label {
  cursor: pointer;
  margin: 0;
  min-height: 1em;
  padding: 5px 7px 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-results .select2-highlighted {
  color: #ccc;
}
.select2-results .select2-highlighted em {
  background: transparent;
}
.select2-results .select2-highlighted ul {
  background: #fff;
  color: #555555;
}
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  color: #999;
  display: block;
  font-style: italic;
}
.select2-results .select2-disabled,
.select2-results .select2-disabled.select2-highlighted {
  color: #888;
  cursor: default;
  display: block;
}
.select2-results .select2-selected {
  display: none;
}
.select2-more-results {
  display: block;
}
.select2-container.select2-container-disabled .select2-choice {
  background-color: #eeeeee;
  cursor: default;
}
.select2-container.select2-container-disabled .select2-choice .select2-arrow:before {
  display: none !important;
}
.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}
.select2-container-multi .select2-choices {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-top-color: #c2c2c2;
  border-radius: 2px;
  cursor: text;
  height: auto !important;
  height: 1%;
  margin: 0;
  overflow: hidden;
  padding: 5px 5px 0 0;
  position: relative;
  width: 100%;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.select2-container-multi .select2-choices li {
  display: block;
  float: left;
  list-style: none;
}
.select2-container-multi .select2-choices .select2-search-field {
  display: block;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.select2-container-multi .select2-choices .select2-search-field input {
  background: transparent !important;
  border: 0;
  color: #555555;
  font-size: 13px;
  line-height: 1.42857143;
  margin-top: -5px;
  padding: 6px 12px;
  outline: 0;
}
.select2-container-multi .select2-choices .select2-search-choice {
  border-radius: 2px;
  color: #fff;
  cursor: default;
  display: block;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 5px 5px;
  padding: 0 20px 0 6px;
  position: relative;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice + .select2-search-field input {
  padding-left: 6px;
  padding-right: 0;
}
.select2-container-multi.select2-container-active .select2-choices {
  outline: none;
}
.select2-locked {
  padding: 0 6px !important;
}
.select2-default {
  color: #999 !important;
}
.select2-search-choice-close {
  display: block;
  color: #fff;
  font-family: FontAwesome;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  position: absolute;
  right: 3px;
  text-align: center;
  top: 0;
  width: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.select2-search-choice-close:before {
  content: "\f00d";
}
.select2-search-choice-close:hover {
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #eeeeee;
  cursor: default;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  opacity: .7;
  padding: 0 6px;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  background: none;
  display: none;
}
.select2-drop-mask {
  border: 0;
  left: 0;
  height: auto;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: auto;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}
.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}
.select2-display-none {
  display: none;
}
.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}
.select2-container-multi .select2-choices .select2-search-choice {
  background: #b0b0b0;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #979797;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #b0b0b0 !important;
}
.select2-success .select2-container-multi .select2-choices .select2-search-choice {
  background: #5ebd5e;
}
.select2-success .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #43a543;
}
.select2-success .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #5ebd5e !important;
}
.select2-warning .select2-container-multi .select2-choices .select2-search-choice {
  background: #f4b04f;
}
.select2-warning .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #f19a1f;
}
.select2-warning .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #f4b04f !important;
}
.select2-danger .select2-container-multi .select2-choices .select2-search-choice {
  background: #e66454;
}
.select2-danger .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #df3c28;
}
.select2-danger .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #e66454 !important;
}
.select2-info .select2-container-multi .select2-choices .select2-search-choice {
  background: #5bc0de;
}
.select2-info .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #31b0d5;
}
.select2-info .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #5bc0de !important;
}
.has-warning .select2-choice,
.has-warning .select2-choices,
.has-warning.simple .select2-choice,
.has-warning.simple .select2-choices {
  border-color: #eebf5e;
}
.has-error .select2-choice,
.has-error .select2-choices,
.has-error.simple .select2-choice,
.has-error.simple .select2-choices {
  border-color: #d38e99;
}
.has-success .select2-choice,
.has-success .select2-choices,
.has-success.simple .select2-choice,
.has-success.simple .select2-choices {
  border-color: #a3ce7f;
}
.has-warning.dark .select2-choice,
.has-warning.dark .select2-choices {
  border-color: #f4b04f;
}
.has-error.dark .select2-choice,
.has-error.dark .select2-choices {
  border-color: #e66454;
}
.has-success.dark .select2-choice,
.has-success.dark .select2-choices {
  border-color: #5ebd5e;
}
.datepicker-dropdown {
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  left: 0;
  margin-top: 10px;
  top: 0;
  z-index: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.datepicker-dropdown.datepicker-orient-bottom {
  margin-top: -10px;
}
.datepicker-dropdown:after {
  border-bottom: 10px solid rgba(0, 0, 0, 0.2);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 0;
  content: '';
  display: block;
  position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 10.5px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 10.5px;
}
.datepicker-dropdown.datepicker-orient-top:after {
  top: -13px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  border-bottom: 0;
  border-top: 10px solid rgba(0, 0, 0, 0.2);
  bottom: -13px;
}
.modal .datepicker-dropdown {
  z-index: 1040;
}
.datepicker {
  direction: ltr;
  padding: 0;
  width: auto !important;
}
.datepicker.datepicker-inline {
  border: 1px solid #eee;
  display: inline-block;
  position: relative;
}
.datepicker > div {
  display: none;
}
.datepicker.days div.datepicker-days,
.datepicker.months div.datepicker-months,
.datepicker.years div.datepicker-years {
  display: block;
}
.datepicker div.datepicker-months td,
.datepicker div.datepicker-years td {
  width: 210px !important;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker td {
  line-height: 32px;
  padding: 0 !important;
  text-align: center;
  width: 30px;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
.datepicker table tr td {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}
.datepicker table tr td:last-child {
  border-right: none;
}
.datepicker table tr td.day:hover {
  background: #f3f3f3;
  cursor: pointer;
  position: relative;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #bbb;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #eee;
  cursor: default;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #f3f3f3;
  font-weight: 700 !important;
}
.datepicker table tr td.today:hover:hover {
  color: #555555;
}
.datepicker table tr td.today.active:hover {
  color: #fff;
}
.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #f3f3f3;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background: #f3f3f3;
  font-weight: 700;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  color: #fff !important;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  color: #fff !important;
}
.datepicker table tr td span {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  float: left;
  line-height: 54px;
  margin: 1%;
  width: 23%;
}
.datepicker table tr td span:hover {
  background: #eee;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #eee;
  cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #eee;
}
.datepicker thead tr:first-child th,
.datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker th,
.datepicker td,
.datepicker tr {
  border-top: none !important;
  text-align: center !important;
}
.datepicker thead th,
.datepicker tfoot th {
  font-weight: 600;
  padding: 0 !important;
}
.datepicker thead th {
  border-bottom: none !important;
  color: #fff;
  font-weight: 600;
  line-height: 35px !important;
  height: 35px !important;
  padding: 0 !important;
  text-align: center;
}
.datepicker thead tr:first-child {
  border-bottom: 1px solid !important;
}
.datepicker thead tr:first-child th:hover {
  background: rgba(255, 255, 255, 0.2);
}
.datepicker thead tr:last-child {
  border-bottom: none !important;
}
.datepicker thead th.prev,
.datepicker thead th.next {
  color: rgba(0, 0, 0, 0);
  display: block;
  font-size: 0;
  line-height: 0;
  position: absolute;
  width: 36px !important;
}
.datepicker thead th.prev:after,
.datepicker thead th.next:after {
  bottom: 0;
  color: #fff;
  display: block;
  font-family: FontAwesome;
  font-size: 14px;
  font-weight: normal;
  left: 0;
  line-height: 35px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 36px;
}
.datepicker thead th.prev {
  left: 0;
  border-right: 1px solid;
}
.datepicker thead th.prev:after {
  content: "\f053";
}
.datepicker thead th.next {
  border-left: 1px solid;
  right: 0;
}
.datepicker thead th.next:after {
  content: "\f054";
}
.datepicker tfoot th:hover {
  background: #eee;
}
.datepicker tbody td,
.datepicker tfoot th {
  line-height: 32px !important;
}
.datepicker > .datepicker-days tbody .cw {
  background: #f8f8f8 !important;
  color: #aaa;
  font-size: 8px;
  font-weight: 600;
}
.datepicker > .datepicker-days thead tr:first-child th.cw {
  background-color: transparent;
  cursor: default;
}
.datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  left: 30px;
  border-left: 1px solid;
}
.datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-left: 1px solid;
}
.input-daterange input {
  text-align: center;
}
.date .input-group-addon {
  cursor: pointer;
}
