
/*********************************************************************************************

1.  Tipografia           
2.  Cores 
3. 	Cores do template
4.  Header
5.  Template
6. 	Footer - rodapé

**********************************************************************************************/

/********************************************************************************************* 

1.  Tipografia

*********************************************************************************************/
/* Font Oxygen*/
@mixin oxygen-light(){
	font-family: 'Oxygen', sans-serif;
	font-weight: 600;
}

@mixin oxygen-regular(){
	font-family: 'Oxygen', sans-serif;
	font-weight: 400;
}

@mixin oxygen-bold(){
	font-family: 'Oxygen', sans-serif;
	font-weight: 700;
}

/* Font Cabin*/
@mixin cabin(){
	font-family: 'Cabin', sans-serif;
	font-weight: 400;
}

@mixin cabin-bold(){
	font-family: 'Cabin', sans-serif;
	font-weight: 700;
}

/* Titulo blue*/
@mixin titulo-blue(){
	@include cabin-bold();
	color: $blue;
	font-size: 36px;
}

/* Botão serviços no menu*/
@mixin bnt-servicos-red(){
	background: #d54d3c; /* Old browsers */
	background: -moz-linear-gradient(top,  #d54d3c 0%, #d75646 55%, #d96051 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d54d3c), color-stop(55%,#d75646), color-stop(100%,#d96051)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #d54d3c 0%,#d75646 55%,#d96051 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #d54d3c 0%,#d75646 55%,#d96051 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #d54d3c 0%,#d75646 55%,#d96051 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #d54d3c 0%,#d75646 55%,#d96051 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d54d3c', endColorstr='#d96051',GradientType=0 ); /* IE6-9 */
}
/* Botão azul padrao*/
@mixin bnt-blue(){
	background: #1d8ad0; /* Old browsers */
	background: -moz-linear-gradient(top,  #1d8ad0 0%, #1c81c2 45%, #1a7bbb 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#1d8ad0), color-stop(45%,#1c81c2), color-stop(100%,#1a7bbb)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #1d8ad0 0%,#1c81c2 45%,#1a7bbb 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #1d8ad0 0%,#1c81c2 45%,#1a7bbb 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #1d8ad0 0%,#1c81c2 45%,#1a7bbb 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #1d8ad0 0%,#1c81c2 45%,#1a7bbb 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d8ad0', endColorstr='#1a7bbb',GradientType=0 ); /* IE6-9 */
}

/* Botão azul padrao hover*/
@mixin bnt-blue-hover(){
	background: #1a7bbb; /* Old browsers */
	background: -moz-linear-gradient(top,  #1a7bbb 0%, #1c81c2 55%, #1d8ad0 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#1a7bbb), color-stop(55%,#1c81c2), color-stop(100%,#1d8ad0)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #1a7bbb 0%,#1c81c2 55%,#1d8ad0 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #1a7bbb 0%,#1c81c2 55%,#1d8ad0 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #1a7bbb 0%,#1c81c2 55%,#1d8ad0 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #1a7bbb 0%,#1c81c2 55%,#1d8ad0 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a7bbb', endColorstr='#1d8ad0',GradientType=0 ); /* IE6-9 */
}

/********************************************************************************************* 

4.  Header

*********************************************************************************************/
.logo{

	img{
		text-align: center;
	}
}
.box-atendimento{

	h6{
		color: $blue;
		@include oxygen-bold();
		font-size: 16px;
		padding-top: 5px;
	}
	p{
		@include oxygen-regular();
		font-size: 14px;
		color: $gray-text;
	}
	img{
		float: left;
		padding-right: 20px;
	}	
}
.box-email-contato{
	h6{
		color: $blue;
		@include oxygen-bold();
		font-size: 16px;
		padding-top: 5px;
	}
	p{
		@include oxygen-regular();
		font-size: 14px;
		color: $gray-text;
	}
	img{
		float: left;
		padding-right: 20px;
	}	
}

.buscar-topo{
	margin-top: 10px;
	input[type=search]{
		background-color: white;
		border: 1px solid #ccc;
		height: 60px;
		padding-left: 15px;
		border-radius: 3px;
		display: inline-block;
		vertical-align: top;
	}
	button{
		border-radius: 3px;
		background-color: $blue;
		width: 60px;
		height: 60px;
		border:none;
		margin-left: -6px;
		img{
			vertical-align: middle;
		}
	}
}

/* Menu */
.menu-blue {
	@include bg-menu-blue();
	margin-top: 20px;
	margin-bottom: 35px;
	ul {

		li {
			text-align: center;
			border-top: 1px solid $bg-blue-dropdown;
			padding-top: 20px;
			padding-bottom: 20px;	

			a {
				text-transform: uppercase;
				text-decoration: none;
				@include oxygen-bold;
				letter-spacing: -0.50px;
				color: $white;
				font-size: 18px;
			}

			.dropdown-menu li a {
				border: 0;
				padding: 3px 20px;
			}

			.dropdown-menu, .menu-servicos {
				@include bg-menu-blue;
				border: 1px solid #ccc;
				border: 1px solid rgba(0,0,0,.15);
				border-radius: 2px;
				-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
				box-shadow: 0 6px 12px rgba(0,0,0,.175);
				background-clip: padding-box;
				font-size: 20px;

				.title {
					border: 0;
				}

				li {
					width: 100%;
					padding: 10px 0;

					&:hover {
						background: $menu-hover;
					}

					a {
						font-size: 15px;
						margin: 0;

						&:hover {
							color: $white;
							background-color: transparent;
						}
					}
				}
			}
		}

		li:first-child {
			border-top:none;
		}

		li.btn-acesso-restrito {
			position: relative;

			.dropdown-toggle {
				background: #07072f;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				padding: 15px 20px;
			}

			img {
				margin-left: 5px;
				padding-right: 15px;
			}
		}

		li.bnt-servicos {
			position: relative;

			ul {
				position:absolute; 
				top:25px; 
				left:0;
				background-color: $bg-blue-dropdown; 
				margin-top: 30px;
				
				li {
					display:block; 
					position: relative;
				}
			}

			.dropdown-toggle {
				@include bnt-servicos-red();
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				padding: 15px 20px 15px 20px;
			}

			img {
				margin-left: 5px;
				padding-right: 15px;
			}
		}

		li.bnt-login-blue {
			
			a {
				@include bnt-login-blue();
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				padding: 15px 20px 15px 20px;

				&:hover {
					background: $blue-hover;
				}
			}

			img{
				margin-left: 5px;
			}
		}
	}
}

.menu-gray {
	ul {
		li {
			text-align: center;
			border-top: 1px solid $bg-blue-dropdown;
			padding-top: 20px;
			padding-bottom: 20px;	

			a {
				text-transform: uppercase;
				text-decoration: none;
				@include oxygen-bold;
				letter-spacing: -0.50px;
				color: $white;
				font-size: 18px;
			}

			.dropdown-menu li a {
				border: 0;
				padding: 3px 20px;
			}

			.dropdown-menu, .menu-servicos {
				@include bg-menu-blue;
				border: 1px solid #ccc;
				border: 1px solid rgba(0,0,0,.15);
				border-radius: 2px;
				-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
				box-shadow: 0 6px 12px rgba(0,0,0,.175);
				background-clip: padding-box;

				.title {
					border: 0;
				}

				li {
					width: 100%;
					padding: 10px 0;

					&:hover {
						background: $menu-hover;
					}

					a {
						font-size: 15px;
						margin: 0;

						&:hover {
							color: $white;
							background-color: transparent;
						}
					}
				}
			}
		}

		li:first-child {
			border-top:none;
		}

		li.btn-acesso-restrito {
			position: relative;

			.dropdown-toggle {
				background: #07072f;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				padding: 15px 20px;
			}

			img {
				margin-left: 5px;
				padding-right: 15px;
			}
		}

		li.bnt-servicos {
			position: relative;

			ul {
				position:absolute; 
				top:25px; 
				left:0;
				background-color: $bg-blue-dropdown; 
				margin-top: 30px;
				
				li {
					display:block; 
					position: relative;
				}
			}

			.dropdown-toggle {
				@include bnt-servicos-red();
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				padding: 15px 20px 15px 20px;
			}

			img {
				margin-left: 5px;
				padding-right: 15px;
			}
		}

		li.bnt-login-blue {
			
			a {
				@include bnt-login-blue();
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				padding: 15px 20px 15px 20px;

				&:hover {
					background: $blue-hover;
				}
			}

			img{
				margin-left: 5px;
			}
		}
	}
}

.separated {
	border-right: 1px solid rgba(231, 238, 239, 0.8);
}

/* Menu de servicos (dropdown) */
.menu-servicos {
	color: #fff;

	.title {
		border: none;
		marign-bottom: 30px;
	}
}

/********************************************************************************************* 

5. Template

*********************************************************************************************/

.box-titulo {
	text-decoration: none;

	h1 {
		@include titulo-blue();
		text-align: center;

		&:hover {
			color:$blue-hover;
		}
	}

	small {
		display: block;
		@include sub-titulo-gray();
		text-align: center;
		margin: 5px 0px 20px;
	}

	&.left{
		h1{
			text-align: left;
		}
	}
}

.box-padrao{
	padding: 10px;
	margin-bottom: 35px;
}
.box-padrao-cinza{
	background-color: $bg-almoste-white;
	vertical-align: middle;
	padding: 20px 0px 20px 0px;
}

/********************************************************************************************* 

6.  Footer - Rodapé

*********************************************************************************************/
.rodape{
	background-color: #f4f2f2;
	padding: 15px 0px 15px 0px;
	margin-top: 20px;
}
.copyright{

	p{
		font-size: 13px;
		@include oxygen-regular();
		color: $gray-text;
		text-align: center;
		padding: 5px 0px 5px 0px;
		letter-spacing: -0.50px;
	}
}
.telefone-footer{

	p{
		font-size: 13px;
		@include oxygen-bold();
		color: $gray-dark;
		text-align: center;
		padding: 0px 0px 5px 0px;
	}
}
.endereco-footer{
	
	p{
		font-size: 13px;
		@include oxygen-bold();
		color: $gray-dark;
		text-align: center;
		padding: 0px 0px 5px 0px;
		letter-spacing: -0.50px;
	}
}

.message-container {
	padding: 15px;
}

.block {
	margin-bottom: 20px;
}

.reply:nth-child(odd) {
	background: #e5e5e5;
}

.reply:nth-child(even) {
	background: lightblue;
}

a.list-group-item .list-group-item-heading {
	font-family: Oxygen,sans-serif;
	font-weight: 600;
}

.panel-heading {
	font-family: Oxygen,sans-serif;
	font-size: 18px;
}

.breadcrumb {
	margin-top: 10px;
}

// Fix datetime picker z-index
.ui-datepicker {
  z-index: 99999 !important;
}

.text {
  font-size: 14px;
  margin-top: 20px;
}

// Growl.
#growls {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 5000;
}

.main-navbar-fixed #growls {
  top: 43px;
}

.growl {
  border-radius: 4px;
  margin: 10px;
  position: relative;
  opacity: 0.95;
  filter: alpha(opacity=95);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.growl .growl-title {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 2px;
}
.growl .growl-message {
  font-size: 13px;
}
.growl .growl-close {
  cursor: pointer;
  display: inline-block;
  float: right;
  font-family: helvetica, verdana, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  margin-top: -2px;
}
.growl.growl-incoming {
  opacity: 0;
  filter: alpha(opacity=0);
}
.growl.growl-outgoing {
  opacity: 0;
  filter: alpha(opacity=0);
}
.growl.growl-small {
  padding: 6px 8px;
  width: 200px;
}
.growl.growl-small .growl-title {
  font-size: 13px;
  margin-bottom: 0;
}
.growl.growl-small .growl-message {
  font-size: 11px;
}
.growl.growl-medium {
  padding: 10px;
  width: 250px;
}
.growl.growl-large {
  padding: 15px;
  width: 300px;
}
.growl.growl-default {
  background: #7f8c8d;
  color: #fff;
}
.growl.growl-error {
  background: #e66454;
  color: #fff;
}
.growl.growl-notice {
  background: #5ebd5e;
  color: #fff;
}
.growl.growl-warning {
  background: #f4b04f;
  color: #fff;
}

/* Callouts */
.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

.callout-info {
  border-left-color: #1b809e;
}

.callout-info h4 {
  color: #1b809e;
}

.callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.email-bg {
  background-color: #005B8D;

  img {
    display: block;
    float: left;
  }

  .heading {
    display: block;
    float: left;
    color: white;
    margin-top: 100px;
  }
}