@charset "UTF-8";
/*********************************************************************************************
        
1.  Login
2.	Conteúdo 
3.	responsivo

**********************************************************************************************/

/********************************************************************************************* 

1.  Login

*********************************************************************************************/
.menu-gray {
	background-color: #E8E8E8
}

.box-login {
	padding: 0px;
	margin-bottom: 35px;

	h1 {
		@include cabin-bold();
		color: $gray-dark;
		font-size: 32px;
		text-align: center;
	}

	input {
		height: 50px;
		background-color: white;
		padding-left: 10px;
		border-radius: 3px;
		border: 1px solid #ccc;
		width: 100%;
		margin-bottom: 10px;
		color: #000;
		@include cabin();

    &::-webkit-input-placeholder {
      color: #999;
    }

    &:-moz-input-placeholder {
      color: #999;
    }

    &::-moz-input-placeholder {
      color: #999;
    }

    &::-ms-input-placeholder {
      color: #999;
    }
	}
  
	button {
		@include bnt-blue();
		text-align: center;
		width: 100%;
		padding: 20px 0px 20px 0px;
		border: none;
		color: $white;
		border-radius: 3px;
		@include cabin();
		font-size: 18px;
		&:hover{
			@include bnt-blue-hover();
		}
	}
}

/********************************************************************************************* 

2.  Conteúdo

*********************************************************************************************/
/* Notícias */
.box-noticias{
	min-height: 525px;
}
.box-noticias-cinza{
	min-height: 505px;
}
.box-noticias-titulo{

	a{
		text-decoration: none;
		h1{
			@include titulo-blue();
			text-align: center;
			&:hover{
				color:$blue-hover;
			}
		}		
	}
	
}

/* Box cinza dos conteúdos*/
.box-padrao {
	padding: 10px;
	margin-bottom: 35px;
}

.box-padrao-cinza {
	background-color: #fbfafa;
	vertical-align: middle;
	padding: 20px 0px 20px 0px;
}

.box-noticias-itens {
	padding: 0px 0px 10px 0px;

	img {
		width: 100%;
		margin-bottom: 15px;
		margin-top: -20px;
	}

	p {
    	color: #000;
		font-size: 12px;
		@include oxygen-regular();
		padding: 0px 15px 0px 15px;
		margin: 0;
	}

	h4 {
		@include oxygen-bold();
		font-size: 18px;
		color: $gray-dark;
		text-align: center;
	}

	a {
		text-decoration: none;

		h5 {
      		color: #333;
			font-size: 16px;
			@include oxygen-light();
			padding-bottom: 10px;
			text-align: left;
			margin: 5px 15px;
			line-height: 26px;

			span {
				@include oxygen-bold();
				color: $gray-dark;
				font-size: 14px;
				padding-left: 5px;

				&:hover{
					color:$blue;
				}
			}
		}
	}
	
	h6{
		color: $gray-text;
		font-size: 12px;
		@include oxygen-regular();
		padding-bottom: 10px;
		text-align: center;
		margin-top: 30px;
	}

	a{

		h4{
			@include oxygen-bold();
			font-size: 18px;
			color: $gray-dark;
			text-align: center;
		}
	}
	
}

/* Box Palavra Grao Mestre*/
.box-grao-mestre-titulo{

	a{
		text-decoration: none;
		h1{
			@include titulo-blue();
			text-align: center;
			&:hover{
				color:$blue-hover;
			}
		}		
	}
}

.box-grao-mestre{
	min-height: 279px;
	padding: 20px;
	h6{
		color: $gray-text;
		font-size: 12px;
		@include oxygen-regular();
		padding-bottom: 10px;
		text-align: center;
		margin-top: 30px;
	}
	h5{
		@include oxygen-bold();
		font-size: 18px;
		color: $gray-dark;
		text-align: center;
		line-height: 28px;
	}
}

/* Box Revista */
.box-revista{

	a{
		text-decoration: none;
		h1{
			@include titulo-blue();
			text-align: center;
			&:hover{
				color:$blue-hover;
			}
		}		
	}
	p{
		@include oxygen-bold();
		font-size: 12px;
		color: $gray-text;
		padding-top: 20px;
	}
	h6{
		@include oxygen-regular();
		font-size: 14px;
		color: $gray-text;
		padding-top: 20px;
		padding-bottom: 20px;
		line-height: 24px;
	}
}
.bnt-download{
	margin-top: 20px;
	display: block;
	a{
		@include bnt-login-blue();
		padding: 10px 20px 10px 20px;
		color: $white;
		&:hover{
			@include bnt-blue-hover();
		}
	}
}
.box-revista-img{
	width: 150px;
	height: 200px;
	float: left;
	margin-right: 20px;
}

/* Box Agenda */

.box-agenda{
	padding: 15px;
	min-height: 577px;
}
.box-agenda-titulo{

	a{
		text-decoration: none;
		h1{
			@include titulo-blue();
			text-align: center;
			&:hover{
				color:$blue-hover;
			}
		}		
	}
}

.box-agenda-itens{
padding: 15px 0px 15px 0px;
border-bottom: 1px solid #edebeb;
	img{
		width: 100%;
		margin-bottom: 15px;
		margin-top: -20px;
	}
	p{
		color: $gray-text;
		font-size: 12px;
		@include oxygen-regular();
		padding: 0px 10px 0px 10px;

	}
	h4{
		@include oxygen-bold();
		font-size: 18px;
		color: $gray-dark;
		text-align: center;
	}
	a{
		text-decoration: none;
		
		h5{
			color: $gray-text;
			font-size: 14px;
			@include oxygen-light();
			padding-bottom: 10px;
			text-align: left;
			padding-bottom: 15px;
			line-height: 26px;
			&:hover{
				color:$gray-dark;
			}
		}
	}
	
	h6{
		color: $gray-text;
		font-size: 12px;
		@include oxygen-regular();
		padding-bottom: 10px;
		text-align: center;
	}

	a{

		h4{
			@include oxygen-bold();
			font-size: 18px;
			color: $gray-dark;
			text-align: center;
		}
	}	
}

.agenda-data{
	width: 40px;
	float: left;
	padding-right: 15px;
	h5{
		@include oxygen-bold();
		font-size: 22px;
		color: $gray-dark;
	}
	p{
		@include oxygen-bold();
		font-size: 14px;
		color: $gray-dark;
		padding: 0;
	}
}

/* Box eventos */
.box-eventos{
	min-height: 577px;
	padding: 20px;
	h6{
		color: $gray-text;
		font-size: 12px;
		@include oxygen-regular();
		padding-bottom: 10px;
		text-align: center;
		padding-top: 20px;
	}
	h5{
		@include oxygen-bold();
		font-size: 18px;
		color: $gray-dark;
		text-align: center;
		line-height: 28px;
	}
	
}
.box-eventos-titulo{

	a{
		text-decoration: none;
		h1{
			@include titulo-blue();
			text-align: center;
			&:hover{
				color:$blue-hover;
			}
		}		
	}
}
.box-eventos-fotos{
	margin-top: 15px;
	img{
		margin-bottom: 15px;
  	width: 100%;
	}
}
.bnt-eventos{
	margin: 30px 15px 15px 15px;
	text-align: center;
	a{
		color: $white;
		@include oxygen-bold();
		font-size: 16px;
		@include bnt-blue();
		padding: 10px;
		width: 100%;
		border-radius: 3px;
		&:hover{
			@include bnt-blue-hover();
		}
	}
}



/********************************************************************************************* 

3.  Responsivo

*********************************************************************************************/
/* Grid 720px+ */
@media (min-width: 720px) {
	.menu-blue{
		margin-top: -15px;
	}
	.box-eventos-fotos img{
		width: initial;
  	margin: 15px;
	}	
	.box-eventos{
		min-height: initial;
		a{
			padding: 10px 30px 10px 30px; 
		}
	}
	.box-agenda{
		min-height: initial;
	}
	.box-grao-mestre{
		min-height: initial;
		h6{
			margin-top: 30px;
		}
	}
	.box-noticias{
		min-height: initial;
	}
	.box-noticias-cinza{
		min-height: initial;
	}
}

/* Grid 1024px+ */
@media only screen and (min-width: 1024px) {
	.menu-blue{
		padding: 15px 0px 15px 0px;
		margin-bottom: 25px;
		ul {
			
			li{
				display: inline-block;
				border-top:none;
				padding: 0px 11px 0px 11px;
			}
			
			li.bnt-login-blue{
				padding: 15px 0px 15px 0px;
			}
		}
	}
	.menu-gray {
		li.bnt-servicos{
			padding: 15px 0px 15px 0px;
		}
	}
	.box-login{
		margin-bottom: 0px;
		h1{
			display: inline-block;
			width: 24.99%;
			float: left;
			margin: 0px;
		} 
		input{
			width: 24.99%;
			float: left;
			margin-right: 10px;
		}
		button{
			width: 20.99%;
			padding: 13px 0px 13px 0px;
		}
	}

	.box-eventos{
		min-height: 716px;
		a{
			padding: 10px 30px 10px 30px; 
		}
	}
	.box-agenda{
		min-height: 577px;;
	}
	.box-grao-mestre{
		min-height: 279px;
	}
	.box-noticias{
		min-height: 525px;
	}
	.box-noticias-titulo a h1{
		margin: 0px;
	}
	.box-noticias-cinza{
		min-height: 598px;
		padding: 20px 0px 0px 0px;
	} 
	.box-eventos-fotos img{
		margin: 4px;
  	width: 30%;
	}
	.box-padrao{
		margin-bottom: 0px;
	}
}
/* Grid 1200px+ */
@media only screen and (min-width: 1200px) {	
	.box-agenda-itens {
  	padding: 10px 0px 0px 0px;
	}
	.box-padrao{
		margin-bottom: 0px;
	}
	.box-noticias-cinza{
		min-height: 496px;
	}
	.box-grao-mestre-titulo a h1{
		margin: 0px 0px 20px 0px;
	}
	.box-agenda-titulo a h1{
		margin: 0px 0px 20px 0px;
	}
	.box-eventos-titulo a h1{
		margin: 0px 0px 20px 0px;
	}
	.box-revista a h1{
		margin: 0px 0px 20px 0px;
	}
	.box-eventos {
	  min-height: 577px;
	}
	.menu-blue ul li{
		padding: 0px 20px 0px 20px;
	}
}