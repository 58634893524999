@charset "UTF-8";

.user-photo{
	width: 200px;
	height: 200px;
	margin: 0 auto;
	border: 1px solid #eee;
	border-radius: 50%;
	overflow: hidden;
	img{
		width: 110%;
		height: auto;
		margin-left: -10px;
	}
}

ul.aside-user-info{
	margin: 10px 0px;
	padding: 0px;
	list-style: none;
	li{
		font-size: 14px;
		font-weight: 200;
		color: $gray-dark;
		margin: 15px 0px;
		padding: 0px 10px;
	}
	li.title{
		font-size: 16px;
		font-weight: 800;
		margin-top: 30px;
		padding-bottom: 10px;
		border-bottom: 2px solid #eee;
	}
}

.form-user{
	margin: 0px;
	padding: 0px;
	hr{
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #ccc;
		margin: 15px 0px;
	}
	label{
		font-size: 13px;
		font-weight: 800;
		color: #666;
		&.label-inline{
			display: block;
			margin: 0px;
			text-align: left;
		}
		@media (min-width: 1024px) {
			&.label-inline{
				margin: 0px;
				text-align: right;
			}
		}
		&.label-check{
			display: inline-block;
			.desc-label{
				float: left;
				margin: 0px 10px;
			}
			.input-field{
				display: inline-block;
			  float: left;
			  width: 20px;
			  height: 20px;
			  margin: 0px;
			}
		}
	}

	.input-field{
	  display: block;
	  width: 100%;
	  height: 35px;
	  padding: 6px 8px;
	  margin-top: -10px;
	  font-size: 14px;
	  line-height: 1.2em;
	  color: #222;
	  background-color: #fff;
	  border: 1px solid #ddd;
	  border-radius: 4px;
	  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.045);
	  box-shadow: inset 0 1px 1px rgba(0,0,0,.045);
	  -webkit-transition: all .15s;
	  -o-transition: all .15s;
	  transition: all .15s;
	}

	.input-field::-webkit-input-placeholder,
	.input-field:-moz-placeholder,
	.input-field::-moz-placeholder,
	.input-field:-ms-input-placeholder{
		font-style: italic;
	  color: #666;
	}

	.input-field:focus{
		outline:none;
		background: #fff;
	  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0);
	  box-shadow: inset 0 1px 1px rgba(0,0,0,0);
	}

	textarea.input-field{
		min-height: 80px;
	}
}

