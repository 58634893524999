/********************************************************************************************* 

Responsivo

*********************************************************************************************/
@media (max-width: 719px) {
  .header-menu li {
    list-style-type: none;
  }

  .dropdown-menu li a {
    text-align: center;
  }
}

/* Grid 720px+ */
@media (min-width: 720px) {
  .menu-blue{
    margin-top: 5px;
  }
  .box-eventos-fotos img{
    width: initial;
    margin: 15px;
  }	
  .box-eventos{
    min-height: initial;
    a{
      padding: 10px 30px 10px 30px; 
    }
  }
  .box-agenda{
    min-height: initial;
  }
  .box-grao-mestre{
    min-height: initial;
    h6{
      margin-top: 30px;
    }
  }
  .box-noticias{
    min-height: initial;
  }
  .box-noticias-cinza{
    min-height: initial;
  }

  .header-menu li {
    list-style-type: none;
  }

  .dropdown-menu li a {
    text-align: center;
  }
}

/* Grid 1024px+ */
@media only screen and (min-width: 1024px) {
  .menu-blue{
    padding: 0;
    margin-bottom: 0;
		ul {
			
			li{
				display: inline-block;
				border-top:none;
				padding: 0px 11px 0px 11px;
			}
			li.bnt-login-blue{
				padding: 15px 0px 15px 0px;
			}
		}
	}
	.menu-gray {
		ul {
			li{
				display: inline-block;
				border-top:none;
				padding: 0px 11px 0px 11px;
			}
			li.bnt-login-blue{
				padding: 15px 0px 15px 0px;
			}
			li.bnt-servicos, li.btn-acesso-restrito {
				padding: 15px 0px 15px 0px;
			}
		}
	}
	.box-login{
		margin-bottom: 0px;
		h1{
			display: inline-block;
			width: 24.99%;
			float: left;
			margin: 0px;
		} 
		input{
			width: 35%;
			float: left;
			margin-right: 10px;
		}
		button{
			width: 20.99%;
			padding: 13px 0px 13px 0px;
		}
	}

	.box-eventos{
		min-height: 716px;
		a{
			padding: 10px 30px 10px 30px; 
		}
	}
	.box-agenda{
		min-height: 577px;;
	}
	.box-grao-mestre{
		min-height: 279px;
	}
	.box-noticias{
		min-height: 525px;
	}
	.box-noticias-titulo a h1{
		margin: 0px;
	}
	.box-noticias-cinza{
		min-height: 598px;
		padding: 10px 0px 0px 0px;
	} 
	.box-eventos-fotos img{
		margin: 0 auto;
		display: block;
	}
	.box-padrao{
		margin-bottom: 0px;
	}

  .menu-servicos {
    width: 600px;
  }
}
/* Grid 1200px+ */
@media only screen and (min-width: 1200px) {
	.box-agenda-itens {
  	padding: 10px 0px 0px 0px;
	}
	.box-padrao{
		margin-bottom: 0px;
	}
	.box-noticias-cinza{
		min-height: 496px;
	}
	.box-grao-mestre-titulo a h1{
		margin: 0px 0px 20px 0px;
	}
	.box-agenda-titulo a h1{
		margin: 0px 0px 20px 0px;
	}
	.box-eventos-titulo a h1{
		margin: 0px 0px 20px 0px;
	}
	.box-revista a h1{
		margin: 0px 0px 20px 0px;
	}
	.box-eventos {
	  min-height: 577px;
	}
	.menu-blue ul li{
		padding: 10px 10px 0px 10px;
	}
}