// Tab content
.tab-content {
  padding: 15px 0;
}

.tab-content.tab-content-bordered {
  border: 1px solid #d7d7d7;
  border-top: none;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

// Nav tabs
.nav-tabs > li > a {
  background-color: #f3f3f3;
  background-color: rgba(0, 0, 0, 0.05);
  border: 0;
  border-bottom: 2px solid #e4e4e4;
  color: #777;
  margin: 0 6px -1px 0;
  padding: 8px 20px;
}

// Notes
.note {
  background: #fafafa;
  border-left: 3px solid #ddd;
  border-right: 0 solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}
.note h1,
.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
  margin-top: 0;
}
.note.note-success {
  border-color: #5ebd5e;
  background: #f4faf2;
}
.note.note-success h1,
.note.note-success h2,
.note.note-success h3,
.note.note-success h4,
.note.note-success h5,
.note.note-success h6 {
  color: #5ebd5e;
}
.note.note-danger {
  border-color: #e66454;
  background: #fbf4f4;
}
.note.note-danger h1,
.note.note-danger h2,
.note.note-danger h3,
.note.note-danger h4,
.note.note-danger h5,
.note.note-danger h6 {
  color: #e66454;
}
.note.note-warning {
  border-color: #f4b04f;
  background: #fbf7de;
}
.note.note-warning h1,
.note.note-warning h2,
.note.note-warning h3,
.note.note-warning h4,
.note.note-warning h5,
.note.note-warning h6 {
  color: #f4b04f;
}
.note.note-info {
  border-color: #5bc0de;
  background: #f6fbfd;
}
.note.note-info h1,
.note.note-info h2,
.note.note-info h3,
.note.note-info h4,
.note.note-info h5,
.note.note-info h6 {
  color: #5bc0de;
}

// Accordion
.accordion-toggle:hover,
.accordion-toggle.collapsed:hover {
  background: rgba(0, 0, 0, 0.015);
  color: #555555;
  text-decoration: none;
}
.accordion-toggle:hover:after,
.accordion-toggle.collapsed:hover:after {
  color: #888;
}

.accordion-toggle:after {
  color: #aaa;
  content: "\f146";
  display: block;
  font-family: FontAwesome;
  font-size: 10px;
  line-height: 36px;
  position: absolute;
  top: 3px;
  right: 15px;
}
.accordion-toggle.collapsed {
  color: #777;
}
.accordion-toggle.collapsed:after {
  content: "\f0fe";
}