@charset "UTF-8";

.box-galeria{
	min-height: 50px;
}

.galeria-itens{
	display: block;
	text-decoration: none;
	.galeria-thumb-image{
		width: 100%;
		height: 300px;
		overflow: hidden;
		@media (min-width: 720px) {
			height: 350px;
		}
		@media only screen and (min-width: 1024px) {
			height: 450px;
		}
		@media only screen and (min-width: 1200px) {
			height: 250px;
		}
		img{
			width: 100%;
			height: auto;
		}
	}
	h4{
		@include oxygen-bold();
		font-size: 22px;
		color: $blue;
		text-align: left;
		padding: 5px 0px;
		margin: 0px;
	}
	&:hover h4{
		color:$blue-hover;
	}
	small{
		display: block;
		font-size: 13px;
		color: $less-gray;
		text-align: left;
		padding: 5px 0px;
		margin: 0px 0px 20px;
	}
}

.galeria-item-show{
	width: 100%;
	min-height: 300px;
	overflow: hidden;
	@media (min-width: 720px) {
		min-height: 350px;
	}
	@media only screen and (min-width: 1024px) {
		min-height: 450px;
	}
	@media only screen and (min-width: 1200px) {
		min-height: 500px;
	}
	img{
		width: 100%;
		height: auto;
	}
	small{
		display: block;
		font-size: 13px;
		color: $less-gray;
		text-align: left;
		padding: 5px 0px;
		margin: 0px 0px 20px;
	}
}
