@charset "UTF-8";

/********************************************************************************************* 

1.  Custom Select2 Lib

*********************************************************************************************/

.select2-container,
.select2-container--default{
	.select2-selection--single{
		height: 35px !important;
		font-size: 14px !important;
  	line-height: 1.2em !important;
		background-color: #fff !important;
		border: 1px solid #ddd !important;
		border-radius: 4px !important;
		padding: 3px 0px !important;
	}
	.select2-selection__arrow{
		top: 5px !important;
	}
}

/********************************************************************************************* 

2. Custom Twitter Bootstrap

*********************************************************************************************/

.dropdown.drop-main-menu{
	.dropdown-menu{
		@include bg-menu-blue();
		li{
			display: block;
			float: none;
			text-align: left;
			border:none;
			padding: 10px 0px;
		}
		a{
			display: block;
			font-size: 15px;
			background: transparent;
			&:hover{
				color: $less-white;
			}
		}
	}
}

/********************************************************************************************* 

3. JQuery UI

*********************************************************************************************/

.auto-complete-action{
	padding: 6px 30px 6px 8px !important;
}

.icon-container-autocomplete{
	position: relative;	
}

.ui-menu .ui-menu-item{
	display: block !important;
	height: 35px !important;
  font-size: 14px !important;
  line-height: 2em !important;
  color: #222 !important;
  border-radius: 0px !important;
  border: none !important;
  padding: 5px 10px !important;
}

.ui-menu .ui-menu-item:hover,
.ui-menu .ui-menu-item:focus,
.ui-menu .ui-menu-item.ui-state-focus,{
	display: block !important;
	height: 35px !important;
  font-size: 14px !important;
  line-height: 2em !important;
  color: #fff !important;
	background: #2983B5 !important;
	padding: 5px 10px !important;
}

